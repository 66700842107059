
import { Presentation } from '../Presentation'; 

export const Home = () => {
    return(
    <div>
        
        <Presentation />
       
     
    </div>
    );
}